import React from "react"
import {
  StaticButton,
  Control,
  Field,
  Select,
} from "../../../StyleComponents/styles"

function SHowMaleFemale({ showGenre, gender, genderC }) {
  return (
    <div>
      {showGenre ? (
        <Field justifyContent="center">
          <Control>
            <StaticButton background="white">Gender </StaticButton>
          </Control>
          <Control>
            <Select>
              <select
                className="staticSelect"
                aria-label="genre"
                value={gender}
                onChange={genderC}
                onBlur={genderC}
              >
                <option value="1">Male</option>
                <option value="2">Female</option>
              </select>
            </Select>
          </Control>
        </Field>
      ) : (
        ""
      )}
    </div>
  )
}

export default React.memo(SHowMaleFemale)
