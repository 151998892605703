import React, { useState, useEffect, useMemo, useCallback } from "react"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"

import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"
import {
  Title,
  SubTitle,
  Columns,
  Column,
  Button,
} from "../../StyleComponents/styles"
import { FlexDiv } from "../../StyleComponents/pagesStyle"

import SHowMaleFemale from "./components/showGenreFemaleMale"
import ReqularRenderList from "./components/regularListRender"

const GoogleAds = React.lazy(() => import("../../components/ADS/ADS"))
// import GoogleAds from "../../components/ADS"

function FrenchFirstNamegenerator(props) {
  const { node } = props.pageContext
  let [result1, setresult1] = useState([])
  let [gender, setgender] = useState("1")
  let [showGenre, setShowGenre] = useState(true)
  const listNumber = 10

  const genderC = useCallback(e => {
    setgender(e.target.value)
  }, [])

  useEffect(() => {
    generate()
  }, [])

  function generate() {
    if (node.name === "Full Names") {
      setShowGenre(false)
      fullRandomizer(node.boy, node.girl)
    } else {
      if (node.girl) {
        setShowGenre(true)
        if (gender === "1") {
          randomizer(node.boy, listNumber)
        } else {
          randomizer(node.girl, listNumber)
        }
      } else {
        setShowGenre(false)
        if (node.name === "Birthday Wish") {
          randomizer(node.boy, 4)
        } else {
          randomizer(node.boy, listNumber)
        }
      }
    }
  }
  function randomizer(list, nuberOfList) {
    let NamesData = []
    for (let i = 0; i < nuberOfList; i++) {
      let random = Math.floor(Math.random() * list.length)
      NamesData.push(list[random])
    }
    setresult1(NamesData)
  }
  function fullRandomizer(list, list2) {
    let NamesData = []
    for (let i = 0; i < 10; i++) {
      let random = Math.floor(Math.random() * list.length)
      let random2 = Math.floor(Math.random() * list2.length)
      NamesData.push(`${list[random]} ${list2[random2]} `)
    }
    setresult1(NamesData)
  }
  return (
    <Layout location={props.location}>
      <SEO
        title={`Random ${node.name} Generator`}
        description={`Random ${node.name} Generator, Generate Random ${node.name} just on click generate, Simple wee help you find the perfect ${node.name}.`}
        keywords={[
          `${node.name} generator, ${node.name} generator, random ${node.name} generator,${node.name} male name generator,${node.name} female name generator, random ${node.name} male female, random male names, random female names,random ${node.name}`,
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Generator", link: "/generator/" },
            { name: `${node.name} Generator`, link: `/${node.path}/` },
          ]
        }, [node.name, node.path])}
      />
      <section className="container">
        <Title>{node.name} generator</Title>
        <SubTitle>Random {node.name} Generator</SubTitle>
        <br />
        <Columns>
          <Column>
            <FlexDiv textAlign="center" minHeight="400px">
              <Button
                className="mb-3"
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                fontSize="1.5rem"
                value="Generate"
                onClick={generate}
              >
                Generate
              </Button>

              <SHowMaleFemale
                showGenre={showGenre}
                gender={gender}
                genderC={genderC}
              />
              <br />
              <ReqularRenderList result1={result1} />
            </FlexDiv>
          </Column>
          <Column>
            {/* google ads UnitPediaRight */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>
        <br />
        <FlexDiv maxWidth="250px" margin="auto">
          <Img
            fluid={node.img.childImageSharp.fluid}
            alt={`random ${node.name} generator`}
          />
        </FlexDiv>
        <br />
        <div>
          <h3>Random {node.name} Generator</h3>
          <p>
            Simply press on the button "generate" to generate a random{" "}
            {node.name}, wee help you to find the perfect {node.name}
          </p>
        </div>
        <br />
        <SeeAlso seeAlsoList={node.SeeAlso} />
        <br />
      </section>
    </Layout>
  )
}

export default FrenchFirstNamegenerator
