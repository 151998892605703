import React from "react"

function ReqularRenderList({ result1 }) {
  return (
    <div>
      {result1.map((e, index) => {
        return (
          <p className="h5" key={index}>
            {e}
          </p>
        )
      })}
    </div>
  )
}

export default React.memo(ReqularRenderList)
